import React from 'react'
import logoWhitePng from '../../assets/images/whitenewlogo.png'
import facebookSvg from '../../assets/images/facebook.svg'
import twitterSvg from '../../assets/images/instagram.svg'
import instagramSvg from '../../assets/images/facebook.svg'
import linkedinSvg from '../../assets/images/linkedin.svg'
import $ from 'jquery'
import './footer.css'
import { scrollToDownload, scrollToFaq, scrollToFeatures } from './helper.js'
const Footer = () => {

    return (
        <footer className="footer">
            <div className="widgets">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 pb-4 pb-md-0 text-center text-md-center pb-5 pb-md-0 ">
                           <a href='/'><img src={logoWhitePng} alt="logo-white" className="footer-logo img-fluid" /></a>
                            <br/>
                            <a href='mailto:support@encryptedid.com' className='contactmail'>support@encryptedid.com</a>
                        </div>
                        <div className="col-6 col-sm-4 col-md-3 pb-4 pb-sm-0">
                            <div className="widget-title">Company</div>
                            <ul className="nav-list">
                                <li> <a href="/">Home</a> </li>
                                <li> <a href="/about">About us</a> </li>
                                <li> <a href="/privacypolicy">Privacy policy</a> </li>
                                <li> <a href="/termsandservice">Terms of service</a> </li>
                            </ul>
                        </div>
                        <div className="col-6 col-sm-4 col-md-3 pb-4 pb-sm-0">
                            <div className="widget-title">Product</div>
                            <ul className="nav-list">
                                <li><button type="button" onClick={scrollToDownload} className="liBtn" id='downloadBtn'>Download</button>  </li>
                                <li><button type="button" onClick={scrollToFeatures} className="liBtn" id="featuresBtn">Features</button>  </li>
                                <li><button type="button" onClick={scrollToFaq} className="liBtn" id="faqBtn" >FAQs</button>  </li>
                            </ul>
                        </div>
                        <div className="col-sm-4 col-md-3">
                            {/* <div className="widget-title">Follow us on</div>
                            <ul className="social-list">
                                <li> <a href="#">
                                    <img src={facebookSvg} alt="facebook" className="img-fluid"/>
                                </a> </li>
                                <li> <a href="#">
                                    <img src={instagramSvg} alt="instagram" className="img-fluid"/>
                                </a> </li>
                                <li> <a href="#">
                                    <img src={twitterSvg} alt="twitter" className="img-fluid" />
                                </a> </li>
                                <li> <a href="#">
                                    <img src={linkedinSvg} alt="linkedin" className="img-fluid" />
                                </a> </li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="text-center">Copyright © 2023 <span className="text-white">Encrypted ID.</span> All Rights reserved.</div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    )
}

export default Footer