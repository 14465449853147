import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import './card.css'
import cardImg from './card.png'


export default function JACard(props) {
    return (
        
    
               <div>
                 <h5 className="pb-3">Your card</h5>
                <div className="card-block">
                    <div className="id-title py-3">
                        <h5>ID Card</h5>
                    </div>
                    <div className="card-block-content">
                        <div></div>
                        <div className="qr-card" dangerouslySetInnerHTML={{ __html: props.qr }}></div>

                        <div className="card-content">
                            <div className="d-flex">
                                <div className="left">Family Identification Number</div>
                                <div className="right"><span>:</span>{props.ja ? props.ja : '857xxxxxxx'}</div>
                            </div>
                            <div className="d-flex">
                                <div className="left">Name</div>
                                <div className="right"><span>:</span> {props.name ? props.name : 'Abhishek Aggarwal'}</div>
                            </div>
                            <div className="d-flex">
                                <div className="left">Gender</div>
                                <div className="right"><span>:</span>{props.gender ? props.gender : 'Male'}</div>
                            </div>
                            <div className="d-flex">
                                <div className="left">Date of Birth</div>
                                <div className="right"><span>:</span> {props.dob ? props.dob : '12-08-1997'}</div>
                            </div>
                            <div className="d-flex">
                                <div className="left">Personal Identification Number</div>
                                <div className="right"><span>:</span> {props.uid ? props.uid : '124875xxxxx'}</div>
                            </div>
                        </div>
                        <div className="card-user">
                            <img
                                src={props.image}
                                alt="card-user" style={{ "maxWidth": '72px' }} />
                        </div>
                    </div>
                </div>
               </div>
           
    );
}