import React from 'react'

const Faq = () => {
  return (
    <section className="faq">
    <div className="container">
        <div className="block-heading pb-4">
            <h2>Are You New to QR Code Landscape?</h2>
            <h2>Learn from the Best!</h2>
            <p>Basics to get you started with QR Codes creation.</p>
        </div>
        <div className="row justify-content-center">
            <div className="col-xl-8">
                <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                            What is a QR code?
                        </button>
                      </h2>
                      <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">QR stands for ‘Quick Response,’ which is a type of two-dimensional barcode that can be read easily with the support of a digital device. It can store a large amount of data/information as a series of pixels in a square-shaped grid.                            </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                            What is an Encrypted ID - QR code generator?
                        </button>
                      </h2>
                      <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">It is a tool designed specifically to create QR codes and complete ID cards, depending on your purposes. You can easily download it to your device and utilize it anywhere without the internet.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                            Are users’ personal data safe?
                        </button>
                      </h2>
                      <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Yes, we have created a platform where all users' data are processed on the local machine, not accessible to third parties. Hence, keeping it safe and secure from any malware.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingThree">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                            Is it Customizable?
                          </button>
                        </h2>
                        <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">Yes, customizable solutions are available as per user requirements. Get in touch with our experts for more details.                            </div>
                        </div>
                      </div>
                </div>
            </div>
        </div>
    </div>
</section>

  )
}

export default Faq