import React from 'react'
import cardDemoSvg from '../../assets/images/card-demo.png'
import QRSvg from '../../assets/images/qrs.svg'
import axios from 'axios'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { toast, ToastContainer } from 'react-toastify'
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
// for date Pickers
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { validateDob, validateUID, validateJA, validateName, validateImageType, downloadCard } from './helper';
import JACard from './card';
import { backendUrl } from '../../config';



const GenerateQR = () => {

    const [JA, setJA] = React.useState('')
    const [uid, setUID] = React.useState('')
    const [gender, setGender] = React.useState([]);
    const [name, setName] = React.useState('');
    const [dob, setDOB] = React.useState(null);
    const [uploadImage, setUploadImage] = React.useState()
    const [imageExt, setImageExt] = React.useState('')
    const [previewImage, setPreviewImage] = React.useState()
    const [selectedFile, setSelectedFile] = React.useState()
    const [preview, setPreview] = React.useState()
    const deviceOption = ["Male", "Female", "Other"];
    const svgRef = React.useRef();
    const cardRef = React.useRef();
    const [dobTosend, setDobtoSend] = React.useState('')

    React.useEffect(() => {
        if (!selectedFile) { setPreview(undefined); return }
        setUploadImage(selectedFile.name)
        setImageExt(selectedFile.name.split('.')[1])
        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)
        return () => URL.revokeObjectURL(objectUrl)

    }, [selectedFile])

    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined)
            return
        }
        if (e.target.files[0].size > 1024 * 500) return toast.error("Image should be less than 500Kb")
        setSelectedFile(e.target.files[0])
    }


    const downloadSVG = React.useCallback(() => {
        const svg = svgRef.current.innerHTML;
        const blob = new Blob([svg], { type: "image/svg+xml" });
        downloadBlob(blob, `EncryptedId.svg`);
    }, []);


    async function handleGenerate(e) {
        if (!dob || !JA || !name || !uid || !gender) return toast.error("All fields are Required");
        let month = dob.$M + 1;
        let day = dob.$D
        if (dob.$D <= 9) day = `0${dob.$D}`
        if (dob.$M <= 9) month = `0${dob.$M + 1}`
        let strDob = `${day}/${month}/${dob.$y}`
        setDobtoSend(strDob)
        setName(name.trim())
        setUID(uid.trim())
        setJA(JA.trim())
        if (!validateDob(dobTosend)) return toast.error("Invalid DOB")
        if (!validateName(name)) return toast.error("Invalid Name")
        if (!validateUID(uid)) return toast.error("Invalid Personal Identification Number")
        if (!validateJA(JA)) return toast.error("Invalid Family Identification Number")
        if (!validateImageType(imageExt)) return toast.error("Invalid Image Format")

        try {
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            let userData = { name, JA, UID: uid, DOB: strDob, image: selectedFile, gender: gender.charAt(0) }
            let svg = await axios.post(backendUrl, { ...userData }, config)

            if (svg.status === 200) {
                toast.success("Successfully Generated")
                setPreviewImage(svg.data.toString())

            }

        } catch (error) {
            console.log(error)
            toast.error("Internal Server Error")
        }
    }

    function downloadBlob(blob, filename) {
        const objectUrl = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = objectUrl;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(() => URL.revokeObjectURL(objectUrl), 5000);
    }

    function handleReset() {
        setJA('')
        setUID('')
        setGender([])
        setName('')
        setDOB(null)
        setSelectedFile()
        setPreviewImage()
        setUploadImage()
        setPreview()
        setImageExt()
        setSelectedFile()
    }

    async function downloadCard() {
        const element = cardRef.current;
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL('image/jpeg');

        const pdf = new jsPDF('p');
        // const imgProperties = pdf.getImageProperties(data);
        // const pdfWidth = pdf.internal.pageSize.getWidth()
        // const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width
        let pdfWidth = 86;
        let pdfHeight = 54;
        let width = window.screen.width;
        if (width < 545) pdfHeight = 60
        pdf.addImage(data, 'PNG', 50, 50, pdfWidth, pdfHeight);
        pdf.save('EncryptedCard.pdf');
    }
    return (
        <>
            <ToastContainer pauseOnFocusLoss={false} />
            <section className="qr-generate">
                <div className="container">
                    <div className="block-heading pb-4">
                        <h2>Try It Yourself!</h2>
                        <p>Instantly create your QR code using Encrypted ID</p>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-xl-5 align-self-center mb-4 mb-lg-0">
                            <div className="qr-detail-block">
                                <h5 className="pb-3">Enter your details</h5>
                                <div className="row">
                                    <div className="col-12 col-md-6 col-lg-12">
                                        <div className="mb-3">
                                            <TextField id="outlined-basic" label="Family Identification Number" sx={{ width: '100%', background: 'white' }}
                                                size='large'
                                                error={JA.length > 0 ? (((JA > 999999999 && JA <= 9999999999)) ? false : true) : false}
                                                helperText={JA.length > 0 ? ((JA > 999999999 && JA <= 9999999999) ? '' : 'Invalid Family Identification Number') : ''}
                                                value={JA}
                                                onChange={(newValue) => { setJA(newValue.target.value); }} variant="outlined" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-7">
                                        <div className="mb-3">
                                            <TextField id="outlined-basic" size='large' label="Name" sx={{ width: '100%', background: 'white' }}

                                                value={name} variant="outlined" onChange={(newValue) => { setName(newValue.target.value); }} />

                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-5">
                                        <div className="mb-3">
                                            <Autocomplete
                                                id="deviceStatus"
                                                options={deviceOption}
                                                value={gender}
                                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                                getOptionLabel={(option) => typeof option === 'string'
                                                    || option instanceof String ? option : ""}
                                                onChange={(_, value) => {
                                                    setGender(value);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField sx={{ width: '100%', background: 'white' }} size='small' {...params} variant="outlined" label="Gender" />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-12">
                                        <div className="mb-3">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DesktopDatePicker
                                                    label="Date of Birth"
                                                    size='small'
                                                    disableFuture
                                                    inputFormat="DD/MM/YYYY"
                                                    value={dob}
                                                    onChange={(newValue) => {
                                                        setDOB(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField sx={{ width: '100%', background: 'white' }}{...params} />}
                                                />
                                            </LocalizationProvider> </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="mb-3">
                                            <TextField id="outlined-basic" size='large' label="Personal Identification Number" sx={{ width: '100%', background: 'white' }}
                                                error={uid.length > 0 ? ((uid > 9999999999 && uid <= 99999999999) ? false : true) : false}
                                                helperText={uid.length > 0 ? ((uid > 9999999999 && uid <= 99999999999) ? '' : 'Invalid Personal Identification Number') : ''}
                                                value={uid} variant="outlined" onChange={(newValue) => { setUID(newValue.target.value); }} />

                                        </div>
                                    </div>
                                    <div className="upload-btn-wrapper">
                                        <button className="btn">Upload a Profile pic <br /> *in jpeg less than 500kb </button>
                                        <input type='file' onChange={onSelectFile} />
                                    </div>
                                    <div>
                                        <br />
                                        {selectedFile && <img src={preview} alt='img' style={{ width: '100px', height: '100px' }} />}
                                        <p>{uploadImage}</p>
                                    </div>
                                </div>
                                <button onClick={handleGenerate} className="btn btn-primary m-2 ">Submit</button>
                                {previewImage ? <button onClick={handleReset} className="btn btn-primary m-2">Reset</button> : ''}

                            </div>
                        </div>


                        <div className="col-lg-6 col-xl-5">
                            <div className="qr-output text-center">
                                <div className="output-card pb-0 pb-lg-5">
                                    {previewImage ? <div ref={cardRef} >< JACard name={name} gender={gender} ja={JA} uid={uid} image={preview} dob={dobTosend} qr={previewImage} /></div> : <img src={cardDemoSvg} alt="card-demo" className="img-fluid" />}

                                    <div className="text-center pt-4">
                                        {previewImage ? <button onClick={downloadCard} className="btn btn-primary">Download Card</button> : <></>}
                                    </div>
                                </div>
                                <div className="output-qr">
                                    <h5 className="pb-3">QR Code</h5>
                                    {previewImage ?
                                        <div ref={svgRef} style={{ height: "50%", width: "50%", margin: '0 auto' }} dangerouslySetInnerHTML={{ __html: previewImage }} />
                                        :
                                        <img src={QRSvg} alt="qrs" className="img-fluid" />}
                                    <div className="text-center pt-4">
                                        {previewImage ? <button onClick={downloadSVG} className="btn btn-primary">Download</button> : <></>}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default GenerateQR