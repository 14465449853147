import React from 'react'
import whiteLabelSvg from '../../assets/images/white-label.svg'
import ownAppSvg from '../../assets/images/own-app.svg'
import csImg from '../../assets/images/customized-solution.svg'
import noInternetSvg from '../../assets/images/no-internet.svg'



const Services = () => {
    return (
        <section className="services">
            <div className="container">
                <div className="block-heading pb-4">
                    <h2>Want to Start Your Own QR Generation Service?</h2>
                    <p>We’ve got the best offer, built especially for different needs!</p>
                </div>
                <div className="row">
                    <div className="col-6 col-lg-3 mb-4 mb-lg-0">
                        <div className="service-card first">
                            <div className="service-icon">
                                <img src={whiteLabelSvg} alt="white-label" />
                            </div>
                            <div className="service-heading">White labeled solution</div>
                        </div>
                    </div>
                    <div className="col-6 col-lg-3 mb-4 mb-lg-0">
                        <div className="service-card second">
                            <div className="service-icon">
                                <img src={ownAppSvg} alt="own-app" />
                            </div>
                            <div className="service-heading">Scan from your own app only</div>
                        </div>
                    </div>
                    <div className="col-6 col-lg-3 mb-4 mb-lg-0">
                        <div className="service-card third">
                            <div className="service-icon">
                                <img src={csImg} alt="customized-solution" />
                            </div>
                            <div className="service-heading">Customized solution</div>
                        </div>
                    </div>
                    <div className="col-6 col-lg-3 mb-4 mb-lg-0">
                        <div className="service-card fourth">
                            <div className="service-icon">
                                <img src={noInternetSvg} alt="no-internet" />
                            </div>
                            <div className="service-heading">No internet access required</div>
                        </div>
                    </div>
                </div>
                <div className="text-center mt-3 mt-lg-5">
                    <button className="btn btn-primary">Send Enquiry</button>
                </div>
            </div>
        </section>
    )
}

export default Services