import React from 'react'
import DownloadImg from '../../assets/images/play-store.png'

const AppDownload = () => {
    return (
        <section className="app-download">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="text-center">
                            <h1 className="text-white">Get Your QR Scanning App Right Away!</h1>
                            <p>Download this app to authenticate the above-generated QR codes.</p>
                            <a href="#"><img src={DownloadImg} alt="play-store" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AppDownload