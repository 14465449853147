import {BrowserRouter,Route,Routes} from 'react-router-dom'
import Main from './container/Home'
import TermsAndCondition from './container/TermsandConditon/TermsAndCondition'
import About from './container/About/index'
import PrivacyPolicy from './container/Privacy/PrivacyPolicy'
import './assets/css/bootstrap.min.css'
import './assets/css/styles.css'
function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/"  element={<Main/>} />
      <Route path ='/about' exact element = {<About/>} />
      <Route path = '/termsandservice' element = {<TermsAndCondition/>}/>
      <Route path = '/privacypolicy' element = {<PrivacyPolicy/>}/>


    </Routes>
  </BrowserRouter>
  );
}

export default App;
