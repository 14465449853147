
import validateDate from "validate-date";
import 'react-toastify/dist/ReactToastify.css';

// validates Date of birth
function validateDob(str) {
    return validateDate(str, "boolean", "dd/mm/yyyy")
}

// validates UID Number (it should be of 11 digits)
function validateUID(str) {
    if (str) {
        if (str.match(/^([0-9]{11})$/)) return true;
        return false;
    }
    else return false;
}

// Validates JA Number (it should of 10 digits)
function validateJA(str) {
    if (str) if (str.match(/^([0-9]{10})$/)) return true;
    else return false;
}

// validates Name (that it doesnot contains any special character)
function validateName(str) {
    if (str) {
        var format =  /^[a-zA-Z ]{2,30}$/;
        if (format.test(str)) return true;
    }
    else return false;
}


function validateImageType(ext) {
    if(ext==='jpeg') return true;
    return false;
}
function downloadCard(){
    console.log("downloading card")
    return ;
}
export { validateDob, validateUID, validateJA, validateName,validateImageType,downloadCard }