import React from 'react'
import ImageSvg from '../../assets/images/image.svg'
import Authenticity from '../../assets/images/authenticity.svg'
import Confidentiality from '../../assets/images/Confidentiality.svg'
import Gupgrade from '../../assets/images/Gradual-Upgrades.svg'
import Ccentric from '../../assets/images/Customer-centric.svg'
import './style.css'

// import 
const Content = () => {
    return (
        <div>
            <section className="hero-banner">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 order-lg-2">
                            <div className="card-img">
                                <img src={ImageSvg} alt="card" />
                            </div>
                        </div>
                        <div className="col-lg-6 order-lg-1 hero-content text-center text-md-start pt-4 pt-md-0">

                            <h1 style={{fontSize:'2.8rem'}}>Your Exceptional
                                <span style={{color:'yellow'}}> Digital Experience</span> Begins Here!
                            </h1>
                            <p style={{fontSize:'1.4rem'}}>We’re Encrypted ID, a team with a mission to revolutionize the digital
                                experience and boost the world to move ahead.</p>
                        </div>

                    </div>
                </div>
            </section>

            <section className="how-to-create">
                <div className="container">
                    <div className="block-heading pb-4">
                        <h2>About Us</h2>
                    </div>
                    <p style={{ "textAlign": "center", "fontWeight": "400" }}>We envisioned creating an innovative yet affordable QR code generator that allows individuals and
                        businesses of all sizes an opportunity to streamline their operations and encode information. We
                        wanted to design a solution that provides a digital dimension to a variety of products and
                        services.<br />And so, in 2023, Encrypted ID was born. We have begun our journey to help everyone;
                        brands and corporations worldwide. We’ve ensured to keep our software extensive, customizable, and very simple
                        to use. The website is a demo presentation of our software, where anyone can fill in their
                        information, generate the QR code, and scan through our application.</p>
                </div>
            </section>



            <section className="services">
                <div className="container">
                    <div className="block-heading pb-4">
                        <h2>Our Core Values</h2>
                    </div>
                    <div className="row">
                        <div className="col-6 col-lg-3 mb-4 mb-lg-0">
                            <div className="service-card first">
                                <div className="service-icon">
                                    <img src={Authenticity} alt="Authenticity" />
                                </div>
                                <div className="service-heading">Authenticity</div>
                                <p style={{ "fontSize": "14px", "fontWeight": "400" }}>We, with our team, strive to keep authenticity as a fundamental
                                    value. That’s why we believe in and maintain high transparency with our customers.</p>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3 mb-4 mb-lg-0">
                            <div className="service-card second">
                                <div className="service-icon">
                                    <img src={Confidentiality} alt="Confidentiality" />
                                </div>
                                <div className="service-heading">Confidentiality</div>
                                <p style={{ "fontSize": "14px", "fontWeight": "400" }}>Everyone’s privacy should be respected and we abide by it. We
                                    ensure to keep our customers’ data safe and secure and it will always be our topmost
                                    priority.</p>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3 mb-4 mb-lg-0">
                            <div className="service-card third">
                                <div className="service-icon">
                                    <img src={Gupgrade} alt="Gradual-Upgrades" />
                                </div>
                                <div className="service-heading">Gradual Upgrades</div>
                                <p style={{ "fontSize": "14px", "fontWeight": "400" }}>We follow the Kaizen philosophy of continuously improving
                                    operations and upgrading our system from time to time. We want to give our customers ‘the
                                    best’.</p>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3 mb-4 mb-lg-0">
                            <div className="service-card fourth">
                                <div className="service-icon">
                                    <img src={Ccentric} alt="Customer-centric" />
                                </div>
                                <div className="service-heading">Customer-centric</div>
                                <p style={{ "fontSize": "14px", "fontWeight": "400" }}>We have pledged to prioritize strategies and practices that deliver
                                    the highest customer satisfaction through communications, services,and products.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Content