import React from 'react'
import Footer from '../../components/Footer'
import Navbar from '../../components/Navbar/Navbar'

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <div style={{backgroundColor:'#EDF0FF'}}>
      <div className='container py-5'>
        <h1 style={{textAlign:'center',marginBottom:'2%'}}>Privacy Policy</h1>
        <p>Thank you for using Encrypted ID! Here we will describe the privacy policy of the information we collect, why we collect it, and how we handle it.
        </p> <p>We collect anonymous information related to the pages you visited and viewed. We never share customer data with any 3rd parties except to help us deliver our services. We are fully committed to protecting the personal data of our customers and customers’ end users. These are the key points if you need detail, keep reading.</p>
        <h6>What do we collect? </h6>
        <p>Your account-related information that you provide while signing up. This includes your email address or mobile number. We collect this information in three ways:
        </p> <h6>One Collected Automatically </h6>
        <ul>
          <li>We use cookies or similar mechanisms stored on your device. However, always with your consent or as per the legal bases.</li>
          <li>The IP from which the connection is made, the devices and their characteristics, the version of the operating system, the type of browser you use, the language, the date, the country, the time of the request, the URL that you come from, the URL to which you go to, or the mobile network, used, among others.</li>
          <li>
            <b> Google Analytics:</b> We use it to measure the pages visitors/users view on our website, how you arrived at our site and some basic information about your computer. All the information is anonymous; we don’t know who you are. The data from Google Analytics is to help us understand which parts of our website are working well, what visitors are looking for, how people arrive at our site, and so on. Like most websites, we use the information to make our services better and serve our users best.
          </li>
        </ul>

        <h6> One Provided Voluntarily </h6>
        <ul>
          <li>The personal information you provide during sign-up. The necessary data, including name, mobile number, and email.</li>
          <li>The data you provide us will be incorporated and processed as per the legal guidelines.</li>
        </ul>
        <h6>The one provided by third parties</h6>
        <ul>
          <li>The one provided by payment systems or credit card processors, like the time of purchase or its amount. However, our website is a demo application that shows how a QR code can be generated and scanned by mobile phone.</li>
        </ul>
        <p>We have best-in-class data security strategies to ensure the protection of customers’ and end users’ data. We keep customers’ and end-users data for a certain period of time (after the customers cease to use the company’s applications). However, the customer will always have the right to either download all data or request permanent deletion.
        </p> <p>The QR codes you create with our service encode all content directly in the image itself. Hence, we do not collect, transmit, or store any information you enter. The code is generated directly in the browser without sharing any information with a remote system (that’s why it can work offline).
        </p><p>We don't collect, transmit, or store any data from the Encrypted ID you scan using our Services. Scanning happens locally in your browser, securing it for you. This is our privacy policy; however, we reserve the right to change the privacy policy at any time and all the users will get 7 days prior notice for all the updates/changes in the privacy policy.
        </p><p>If you have a question about our Service, please contact us at _________________. <br /> We’ll get in touch with you as early as possible
        </p>

      </div>
      </div>
      <Footer />
    </>
  )
}

export default PrivacyPolicy