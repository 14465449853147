import React from 'react'
import logo  from '../../assets/images/newlogo.png'
import appStore from '../../assets/images/app-store.svg'
import { scrollToDownload,scrolltoFooter } from '../Footer/helper'
const Navbar = () => {
    return (
        <>
            <header className="header">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-lg-5 align-self-center text-center text-md-left">
                           <a href="/"> <img src={logo} alt="logo" className="logo"></img></a>
                        </div>
                        <div className="col-md-8 col-lg-7 d-flex flex-column flex-md-row justify-content-center justify-content-md-end align-items-center pt-4 pt-md-0">
                            <ul className="nav">
                                <li className="nav-link">
                                    <a href="/about">About us</a>
                                </li>
                                <li className="nav-link">
                                    <a href='#' onClick={scrolltoFooter}>Contact us</a>
                                </li>
                            </ul>
                            <button className="btn btn-primary" onClick={scrollToDownload}><img src={appStore} alt="app-store"/> Download</button>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Navbar