import React from 'react'
import Navbar from '../../components/Navbar/Navbar'
import Footer from '../../components/Footer'
import Content from './Content'
const AboutIndex = () => {
  return (
    <>
    <Navbar/>
    <Content />
    <Footer/>
   
    </>
  )
}

export default AboutIndex