import React from 'react'
import Footer from '../../components/Footer'
import Navbar from '../../components/Navbar/Navbar'

const TermsAndCondition = () => {
  return (
    <>
      <Navbar />
      <div style={{backgroundColor:'#EDF0FF'}}>
      <div className='container py-5' >
      <h1 style={{textAlign:'center',marginBottom:'2%'}}>Terms of Services</h1>
        <h5>Hello, and welcome to Encrypted ID!</h5>
        <h6>PLEASE REVIEW THIS SERVICE AGREEMENT, INCLUDING THE TERMS OF SERVICE, IMPORTANT INFORMATION ABOUT RIGHTS & OBLIGATIONS, CERTAIN RESTRICTIONS & LIMITATIONS, AND EXCLUSIONS TO OUR LIABILITY.</h6>
        <br />
        <p> Encrypted ID provides all its services subject to the terms and conditions (“Terms” or “Agreement”). When we mention “us” and “we”, we are referring to our “Services” and when we write you, your, user, subscriber, them - means the party using, accessing, and scrolling through our website <a href="/">EncryptionLink</a></p>
        <h6 >Software</h6>
        <p>It is a demo application of how users can create customizable QR codes and scan them through their mobile phones. We grant users/subscribers worldwide access to Encrypted ID services for the agreed purpose on the condition that:</p>
        <ul>
          <li>Consumers will abide by the compliance of Permitted Users with the term of this Agreement</li>
          <li>The consumer will be responsible and liable for any misuse of Encrypted ID Services or breach of the terms of this Agreement</li>
        </ul>
        <p> Components of the platform may be offered under an open-source license, and in this case, we will make that license available to you. Provisions of the open-source license may expressly override some of these terms.</p>
        <h6>Acceptance of Terms</h6>
        <p>The Terms of Use (TOU) cover your use and access to our website and related software (our services). By using our services, you agree to these terms and conditions (as defined below). If you do not agree to these terms or our <a href="/privacypolicy">Privacy Policy</a>, then you should not use our services.</p>
        Permitted users are granted to use Encrypted ID services on condition of strict compliance with the following restrictions that under no circumstances shall customers/subscribers will:
        <ul>
          <li>Transmit content that violates the privacy or proprietary rights of Encrypted ID.</li>
          <li> Knowingly or recklessly transmit viruses, trojan horses, worms, malicious codes, or other harmful or destructive content</li>
          <li>Breach of any terms of this Agreement or any applicable law</li>
          <li>Attempt to probe, scan, test, re-engineer, or violate the security features of Encrypted ID</li>
        </ul>
        <h6>Modifications</h6>
        <p>We reserve the right at our sole discretion, to revise, change, modify, or replace any of these Terms of Use at any time with or without notice to you (however, we have the policy to update our users/subscribers seven days before the changes). We also reserve the right to modify or discontinue, temporarily or permanently, the Service (or any part thereof), and impose limits on certain features of the Services with or without notice to you.</p>
        <p>We are not responsible to you or any third party for any modification, suspension, discontinuance, restriction, or limitation of the Service. You should visit this page often to review the latest Terms & Conditions because they are binding on you. Continuing to use our service after the changes to these Terms of Use constitutes your acceptance of those changes.</p>
        <h6>Pricing</h6>
        <p>Generating QR codes, customizing, and scanning through mobile phones is a demo of our complete solution, which stand-alone, without any reference or dependency on our Service. The content is encoded just like printed text; so, technically they are completely independent of us, once created.</p>
        <p>We have a different pricing structure for businesses and organizations looking for a paid model or white-labeled solution. To use this service, the customer agrees to pay Encrypted ID the fees corresponding to the particular Encrypted ID service ordered by them. The fees are set forth with the payment terms set out after final communication with our team and customer. However, such fees are exclusive of all applicable taxes in any relevant jurisdiction, and the User shall be responsible for the payment of all such validly levied taxes.</p>
        <p> Here, the customer acknowledges that all Fees due hereunder are payable in advance and that any delay or failure on the part of the Customer to pay the agreed Fees by the due date shall enable Encrypted ID to suspend or terminate the service and agreement in accordance with its terms.</p>
        <h6> Limitation of Liability</h6>
        <p> We hereby announce to the fullest extent permitted by law, in no event shall we be liable for any damages or loss of data, business, profits, computer hardware, or software. Also, some countries don’t allow the limitation or exclusion of liability in contracts with consumers, and as a result, the contents of this section may not apply to you.</p>
        <p>This limitation of liability is part of the basis of the bargain between the parties, and without it, the terms and prices charged would be different. This limitation of liability shall apply regardless of whether:</p>
        <ul>
          <li>You base your claim on a contract, tort, statute, or any other legal theory.</li>
          <li>Not apply to any damages that we may cause you intentionally or knowingly in violation of this agreement, or knowingly in violation of this Agreement or applicable law, or otherwise mandated by applicable law that cannot be disclaimed from in this Agreement.</li>
        </ul>
        <h6>  Warranties</h6>
        <p> We aim to provide excellent Services and hope you enjoy using them without any difficulty. However, there are things we can’t guarantee. We provide our Services “As is” to the fullest extent permitted by law, and we make no warranties either implied or expressed about the Services, their reliability, or availability.</p>
        <h6>Service Usage Data </h6>
        <p>Encrypted ID will use certain website data for the sole purpose of providing and improving our services. We may collect data to monitor and analyze the usage and traffic patterns of its website for better subscriber service. Such usage does not include any personal information of the customer or user, and no third party will have access to any customer data.</p>
        <h6> Governing Law & Jurisdiction </h6>
        <p>Encrypted ID abides to work and provide services in accordance with the substantive laws of the Government of India.</p>
      </div>
      </div>
      <Footer />
    </>
  )
}

export default TermsAndCondition