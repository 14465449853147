import $ from 'jquery'
function scrollToDownload (){
    $('html,body').animate({
                scrollTop: $(".app-download").offset().top
            },
                'fast');
}
function scrollToFeatures (){
    $('html,body').animate({
                scrollTop: $(".services").offset().top
            },
                'fast');
}
function scrollToFaq (){
    $('html,body').animate({
                scrollTop: $(".faq").offset().top
            },
                'fast');
}
function scrolltoFooter (){
    $('html,body').animate({
                scrollTop: $(".footer").offset().top
            },
                'fast');
}
export {scrollToDownload,scrollToFaq,scrollToFeatures,scrolltoFooter}