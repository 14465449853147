import React from 'react'
import card from '../../assets/images/id card.png'
const Hero = () => {
    return (
        <section className="hero-banner">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 order-lg-2">
                        <div className="card-img">
                            <img src={card} alt="card" />
                        </div>
                    </div>
                    <div className="col-lg-6 order-lg-1 hero-content text-center text-md-start pt-4 pt-md-0">
                        <p>Safest & Fastest way to</p>
                        <h1>Generate QR Code</h1>
                        <h2>Works Offline</h2>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default Hero