import React from 'react'
import mobileCircle from '../../assets/images/imageCircle.png'
import fillSvg from '../../assets/images/fill.svg'
import qrSvg from '../../assets/images/qr-code.svg'
import mobileQrSvg from '../../assets/images/mobile-qr.svg'


const HowToCreateBanner = () => {
  return (
    <section className="how-to-create">
    <div className="container">
        <div className="block-heading pb-3 mb-md-5">
            <h2>How to Create a QR with Encrypted ID?</h2>
            <p>Follow these three simple steps</p>
        </div>
        <div className="row">
            <div className="col-md-6">
                <div className="mobile-cercle text-center mb-5 md-md-0">
                    <img src={mobileCircle} alt="mobile-cercle" className="img-fluid"/>
                </div>
            </div>
            <div className="col-md-6 align-self-center">
                <ul className="process-steps list-unstyled mb-0">
                    <li className="steps-item">
                        <div className="icon-cercle">
                            <img src={fillSvg} alt="fill"/>
                        </div>
                        <div className="list-body">
                            <h3>Fill in Your Information</h3>
                            <p>Enter personal details & upload your photo</p>
                        </div>
                    </li>
                    <li className="steps-item">
                        <div className="icon-cercle">
                            <img src={qrSvg} alt="qr-code"/>
                        </div>
                        <div className="list-body">
                            <h3>Enter to Generate Your QR Code</h3>
                            <p>In a single click, generate your QR code. Download the encrypted & safe data in a QR code or ID card form.</p>
                        </div>
                    </li>
                    <li className="steps-item">
                        <div className="icon-cercle">
                            <img src={mobileQrSvg} alt="mobile-qr"/>
                        </div>
                        <div className="list-body">
                            <h3>Ready to Use</h3>
                            <p>Use our app to verify the authenticity of the QR code generated in real-time.</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>
  )
}

export default HowToCreateBanner