import * as React from 'react';
// components 
import Navbar from '../components/Navbar/Navbar';
import Hero from '../components/Hero/Hero';
import HowToCreateBanner from '../components/HowTocreate/index'
import GenerateQR from '../components/GenerateQR/index';
import AppDownload from '../components/AppDownload/index'
import Services from '../components/Services/index'
import Faq from '../components/Faq/index';
import Footer from '../components/Footer/index'



export default function EncryptedId() {
    return (
        <>
        <Navbar/>
        <Hero/>
        <HowToCreateBanner/>
        <GenerateQR/>
        <AppDownload/>
        <Services/>
        <Faq/>
        <Footer/>

{/* 
            <Grid item xs={12}  >
                <h4 style={{ color: 'red' }}> {error ? error : ''}</h4>
            </Grid>

            <Grid container sx={{ p: 15, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }} >
                <Grid container spacing={2} lg={4}  >

                    <Grid item xs={12}  >
                        <TextField id="outlined-basic" label="Jan Aadhar Number" sx={{ width: '100%' }}
                            size='large'
                            error={JA.length > 0 ? (((JA > 999999999 && JA <= 9999999999)) ? false : true) : false}
                            helperText={JA.length > 0 ? ((JA > 999999999 && JA <= 9999999999) ? '' : 'Invalid JA Number') : ''}
                            value={JA}
                            onChange={(newValue) => { setJA(newValue.target.value); }} variant="outlined" />
                    </Grid>

                    <Grid item xs={12}  >
                        <TextField id="outlined-basic" size='large' label="UID Number" sx={{ width: '100%' }}
                            error={uid.length > 0 ? ((uid > 9999999999 && uid <= 999999999999) ? false : true) : false}
                            helperText={uid.length > 0 ? ((uid > 9999999999 && uid <= 999999999999) ? '' : 'Invalid UID ID') : ''}
                            value={uid} variant="outlined" onChange={(newValue) => { setUID(newValue.target.value); }} />
                    </Grid>

                    <Grid item xs={12}  >
                        <TextField id="outlined-basic" size='large' label="Name" sx={{ width: '100%' }}

                            value={name} variant="outlined" onChange={(newValue) => { setName(newValue.target.value); }} />
                    </Grid>

                    <Grid item xs={12} >
                        <Autocomplete
                            id="deviceStatus"
                            options={deviceOption}
                            value={gender}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            getOptionLabel={(option) => typeof option === 'string'
                                || option instanceof String ? option : ""}
                            onChange={(_, value) => {
                                setGender(value);
                            }}
                            renderInput={(params) => (
                                <TextField sx={{ width: '100%' }} size='small' {...params} variant="outlined" label="Gender" />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                label="DOB"
                                size='small'
                                disableFuture
                                inputFormat="DD/MM/YYYY"
                                value={dob}
                                onChange={(newValue) => {
                                    setDOB(newValue);
                                }}
                                renderInput={(params) => <TextField sx={{ width: '100%' }}{...params} />}
                            />
                        </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12}>
                        <Input
                            accept=".jpeg"
                            id="contained-button-file"
                            type="file"
                            value = ""
                            sx={{ width: '100%' }}
                            onChange={handleImage}
                        />
                    </Grid>

                    <Grid item md={4} lg={4}>
                        <Fab variant="extended" size='large' color="primary" sx={{ width: '100%' }} onClick={handleGenerate} >
                            Generate
                        </Fab>
                    </Grid>
                </Grid>

                <Grid item md={4} lg={4} m={5} >


                    <div ref={svgRef} dangerouslySetInnerHTML={{__html: previewImage}} />
                        {previewImage ? <button onClick={downloadSVG}>Download</button> : ''}



                </Grid>
            </Grid> */}
        </>
    );
}